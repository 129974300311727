// import React from 'react';
// import { Link } from 'react-router-dom';
// import antlogo from '../assets/antlogo.webp';

// function Header() {
//   return (
//     <header className="bg-white text-primary-foreground">
//       <div className="container text-2xl mx-auto px-4 py-10">
//         <div className="flex justify-between items-center">
//           {/* <img src={antlogo} alt="Logo" className="w-14 h-14 rounded-full" /> */}
//           <Link to="/" className="text-2xl text-black font-bold justify-left hover:text-primary-foreground/80 transition-colors">Anthill</Link>
//           <nav>
//             <ul className="flex space-x-6 text-black font-bold">
//               <li><Link to="/" className="hover:text-primary-foreground/80 transition-colors">Главная</Link></li>
//               {/* <li><Link to="/about" className="hover:text-primary-foreground/80 transition-colors">О нас</Link></li>
//               <li><Link to="/uslugi" className="hover:text-primary-foreground/80 transition-colors">Наши услуги</Link></li>
//               <li><Link to="/products" className="hover:text-primary-foreground/80 transition-colors">Продукты</Link></li>
//               <li><Link to="/news" className="hover:text-primary-foreground/80 transition-colors">Новости</Link></li> */}
//               <li><Link to="/contact" className="hover:text-primary-foreground/80 transition-colors">Контакты</Link></li>
//             </ul>
//           </nav>
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../assets/anthill.png';

// function Header() {
//   return (
//     <header className="bg-white text-primary-foreground">
//       <div className="container text-2xl mx-auto px-4 py-10">
//         <div className="flex justify-center items-center">
//           <Link to="/" className="flex items-center space-x-2 hover:text-primary-foreground/80 transition-colors">
//             <img src={logo} alt="Anthill Logo" className="h-10 w-auto" />
//             {/* <span className="text-2xl text-black font-bold">Anthill</span> */}
//           </Link>
//           <nav>
//             <ul className="flex space-x-6 text-black font-bold">
//               {/* <li><Link to="/" className="hover:text-primary-foreground/80 transition-colors">Главная</Link></li> */}
//               {/* <li><Link to="/contact" className="hover:text-primary-foreground/80 transition-colors">Контакты</Link></li> */}
//             </ul>
//           </nav>
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;


import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/anthill.png';

function Header() {
  return (
    <header className="bg-white text-primary-foreground">
      <div className="container text-2xl mx-auto px-4 py-10">
        <div className="flex justify-center items-center">
          <Link to="/" className="flex items-center space-x-2 hover:text-primary-foreground/80 transition-colors">
            <img src={logo} alt="Anthill Logo" className="h-10 w-auto" />
          </Link>
          {/* <nav>
            <ul className="flex space-x-0">
              <li>
                <a 
                  href={require('../assets/Наличие АНТХИЛЛ.xlsx')} 
                  className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition-colors"
                  download
                >
                  Скачать складские запасы
                </a>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </header>
  );
}

export default Header;